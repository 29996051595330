import { Suspense, useEffect } from "react";
import { lazy } from '@loadable/component';
import Poster from "./poster/poster";
import "./home.scss";

const Services = lazy(() => import("./services/services"));
const AboutMe = lazy(() => import("./about-me/aboutMe"));
const Experience = lazy(() => import("./experience/experience"));
const Contact = lazy(() => import("./contact/contact"));
const Footer = lazy(() => import("./footer/footer"));

export default function Home() {

    useEffect(() => {
        window.onbeforeunload = () => window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <Poster />

            <Suspense fallback="">
                <Services />
            </Suspense>

            <Suspense fallback="">
                <AboutMe /> 
            </Suspense>

            <Suspense fallback="">
                <Experience />
            </Suspense>

            <Suspense fallback="">
                <Contact />
            </Suspense>

            <Suspense fallback="">
                <Footer />
            </Suspense>
        </>
    )
}