const Header = () => {
    return (
        <div className="tag anim-text">
            I'm Dragos Homner
            <div className="line"></div>
            Available For Freelance Projects
        </div>
    )
}

export default Header;