const Title = () => {
    return (
        <>
            <div className="anim-text">
                <h1>I'm Full Stack Developer</h1>
                <h1>Turning Your Mega-Vision</h1>
                <h1>
                    Into <span className="del-text"> <span> Bytes </span></span>
                </h1>
            </div>
            <span className="smile-text">Smiles</span>
        </>
    );
}

export default Title;