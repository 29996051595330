const Description = () => {
    return (
        <p className="description anim-text">
            An independent and self-motivated full stack developer with over 3 years of professional experience, proven
            teamwork, creativity, continuous learning and technical skills. A strong link between communication
            and coding from challenging projects and programming jobs. Currently, working as Software Engineer at Microsoft.
        </p>
    );
}

export default Description;