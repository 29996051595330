import { Grid } from "@mui/material";
import Header from "./header";
import Title from "./title";
import Description from "./description";
import { lazy } from '@loadable/component';
import { Suspense } from "react";

const Bubbles = lazy(() => import('./bubbles'));
const Blob = lazy(() => import('./blob'));

const Poster = () => {
    return (
        <div className="poster">
            <Header />
            <Grid container spacing={2}>
                <Grid item lg={8} md={12} sm={12} className="texting" order={{ xs: 2, md:2, lg:1 }}>
                    <Title />
                </Grid> 
                <Grid item lg={4} md={12} sm={12} className="bubbles-container" order={{ xs: 1, md:1, lg:2 }}>
                    <Suspense fallback="">
                        <Bubbles />
                    </Suspense>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={3} lg={2}>
                    <Suspense fallback="">
                        <Blob />
                    </Suspense>
                </Grid>
                <Grid item xs={12} md={9} lg={10}>
                    <Description />
                </Grid>
            </Grid>
        </div>
    );
}

export default Poster;